import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { graphql } from "gatsby";
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { ContentBlock } from "../../components/Blocks";
import { TextBlock } from "../../components/Blocks";
import Pages from "../../components/Pages";
import { ContentList } from "../../components/Lists";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import seoData from "../../components/SEO/data";
import { formatURL, removeEmptyKeys, removePreloader } from "../../helpers";
import { Share } from "../../widgets";
import { Button } from "antd";
import NoScript from "../../components/NoScript";
import { showMore } from "../../constants";
import { useThemeContext } from "../../components/Layout/ThemeContext";

export const query = graphql`
	query RadiosPage($pagePath: String!) {
		hasura {
			radio(limit: 4) {
				title_full
				main_image
				main_image_preview
				slug
			}
			...PageSettings
		}
	}
`;

const FETCH_QUERY = gql`
	query FetchData( $limit: Int, $offset: Int) {
		radio(limit: $limit, offset: $offset) {
			title_full
			main_image
			main_image_preview
			slug
		}
	}
`;

export default function Radio({ data }) {
	const { theme } = useThemeContext();

	function makeData(data = [], type) {
		return data.map(({ title_short, title_full, slug, main_image_preview, main_image }) => {
			const image = get(main_image_preview, "src", "") || get(main_image, "src", "");

			return {
				slug: formatURL(`media/${type}/${slug}`),
				image,
				title: title_short || title_full,
			};
		});
	}

	const [radiostations, setRadiostations] = useState(makeData(get(data, "hasura.radio", []), "radio"));
	const url = "radio";
	const limit = 4;
	const [offset, setOffset] = useState(limit);
	const seo = get(data, "hasura.page_settings[0]", {});

	const [loadRadios, { called, loading, error, data: radiosData }] = useLazyQuery(
		FETCH_QUERY,
		{
			variables: {
				limit,
			},
			partialRefetch: true,
			fetchPolicy: "cache-and-network",
		});

	useEffect(() => {
		if (called && !error && !loading) {
			setRadiostations([...radiostations, ...makeData(get(radiosData, "radio", []), "radio")]);
			setOffset(get(radiosData, "radio", []).length < limit ? null : offset + limit);
		} else if (error) {
			setOffset(null);
		}

		removePreloader(!loading || error);
	}, [loading]);

	return (
		<Pages entity={seo || get(seoData, "radio_page", {})} url={url}>
			<ContentBlock key={"breadcrumbs"}>
				<div className="container">
					<Breadcrumbs currentLabel={"Радиостанции"} />
				</div>
			</ContentBlock>
			<ContentBlock key={"main-content"}>
				<div className={"container"}>
					<TextBlock title={"Радиовещание"}>
						<ContentList items={radiostations} className={"col-12 col-md-6 d-noscript-none"} style={{ width: "100%",
							height: "400px",
							objectFit: "cover" }}/>
						<NoScript>
							<ContentList items={radiostations} className={"col-12 col-md-6"} style={{ width: "100%",
								height: "400px",
								objectFit: "cover" }}/>
						</NoScript>
					</TextBlock>
					<div className="row justify-content-center mb-5 d-noscript-none">
						<Button
							disabled={loading || !offset}
							loading={loading}
							onClick={() =>
								loadRadios({
									variables: {
										offset,
									},
								})
							}
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.text,
								lineHeight: theme.lineHeight.text,
								color: theme.color.body,
								backgroundColor: theme.color.text,
							})}
						>
							{showMore}
						</Button>
					</div>
				</div>
			</ContentBlock>
			<div className={"container"}>
				<Share url={url} pageTitleShort={"Православное радио"} />
			</div>
		</Pages>
	);
}

Radio.propTypes = {
	data: PropTypes.object,
};
